import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../components/layout';

function Page({ pageContext }) {
  const page = pageContext;
  const meta = {
    title: page.frontmatter.title || '',
    description: page.frontmatter.description || '',
  };
  const styles = {
    headingsColor: page.frontmatter.headingsColor || '000000',
    headingsFont: page.frontmatter.headingsFont || 'Times New Roman',
    bannerColor: page.frontmatter.bannerColor || 'ffffff',
    h2FontSize: page.frontmatter.h2FontSize || '2.5rem',
  };
  return (
    <Layout meta={meta} styles={styles}>
      <MDXRenderer>{page.body}</MDXRenderer>
    </Layout>
  );
}

export default Page;
